import Utilities from './Utilities';
const DOTPE_HOST_URL = 'https://api.dotpe.in/api';
const API_URL = 'https://us-central1-gottea-3daa4.cloudfunctions.net';
const ELASTIC_SEARCH_URL = 'https://cors.zimjs.com/https://gottea-analytics.es.asia-south1.gcp.elastic-cloud.com:9243';
const ELASTIC_SEARCH_API_KEY = 'ZWxhc3RpYzpQSHc0dWdpY0NPUFI0UHdiNG1BSzdkSEw=';

const ORDER_STATUSES = {
    'COMPLETED' : [14, 21, 22],
    'REJECTED' : [12],
    'ACTIVE' : [10,11,13]
}

function makeMatchArray(matchObj){
    let matchArr = [];   
    for(const key in matchObj){
      let temp = {terms:{}};
      const filterArray = matchObj[key];
      temp.terms[key] = filterArray;
      matchArr.push(temp);
    }
    return matchArr;
}

function makeDateRangeArray(dateKey, startDate, endDate){
  let matchArr = [];   
  const rangeFilter = {
    range : {
    [dateKey] : {
      "gte" : startDate,
      "lte" : endDate
    }
  }};
  matchArr.push(rangeFilter);
  return matchArr;
}

function makeWilcardQueryArray(searchTerm , terms){
    let finalArr = [];
    const words = searchTerm.trim().split(" ");
    for(let term of terms){
      let matchArr = [];
      for(let word of words){
        const searchObj = {
          wildcard: {
            [term]: `*${word}*`
          }
        };
        matchArr.push(searchObj);
      }
      finalArr.push({ bool : { must : matchArr }});
    }
    return finalArr;
}

function buildDotpeRequest(type, body, endpoint, authToken){
    const reqObj = {
        method: type,
        headers: { 'Content-Type': 'application/json' }
    };
    if(body){
        reqObj.body = JSON.stringify(body);
    }
    if(authToken){
        reqObj.headers['Authorization'] = `Basic ${authToken}`;
    }
    const endUrl = `${DOTPE_HOST_URL}${endpoint}`;
    return new Promise((resolve, reject)=>{
        fetch(endUrl, reqObj)
        .then((response) => response.json())
        .then((json) => resolve(json))
        .catch((error) => { console.log('ERROR', error); reject(error)})
        .finally(() => {})
    });
}

export default class Api {
    getAllOrders(statusType = 'ACTIVE'){
        const statusArr = ORDER_STATUSES[statusType];
        return buildDotpeRequest('POST', {"storeID":6101,"merchantID":2308,"status":statusArr,"from":"2020-11-01 00:00:00"},'/morder/consoleorder');
    }

    getGoalData(goalKey){
        return Utilities.makeWebRequest(`https://us-central1-gottea-3daa4.cloudfunctions.net/sales/goal`,'POST', {
        goalKey
        });
    }

    getGotTeaOrderDataDayWise(startTime, endTime, branchName){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/sales/daywise`,'POST', {
        startTime,
        endTime,
        branchName
        });
    }

    // getGotTeaOrderData(startTime, endTime, branchName){
    //     return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/sales/`,'POST', {
    //     startTime,
    //     endTime,
    //     branchName
    //     });
    // }

    getGotTeaOrderData(startTime, endTime, selectedBranches){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/sales/branchwise`,'POST', {
        startTime,
        endTime,
        selectedBranches
        });
    }

    getCustomerData(startTime, endTime){
        return Utilities.makeWebRequest(`https://us-central1-gottea-3daa4.cloudfunctions.net/customers/gottea`,'POST', {
        startTime,
        endTime
        });
    }

    createMaterial(fields){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/materials`,'POST', fields);
    }

    deleteMaterial(materialId){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/materials`,'DELETE', {id:materialId});
    }

    updateMaterial(materialId,fields){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/materials`,'PUT', {id:materialId,...fields});
    }

    getMaterial(materialId){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/materials/${materialId}`,'GET');
    }

    searchIndex(indexName, searchTerm = '', terms, offset = 0, size = 1000, sort = null, filters = null, dateRange = null){
        searchTerm = Utilities.cleanText(searchTerm);
        const searchObj = () => {  
          let wildcardQuery,obj = {};
          obj.headers =  {'Content-Type': 'application/json', 'Authorization' : `Basic ${ELASTIC_SEARCH_API_KEY}` };
          if(!Utilities.isNullOrEmpty(searchTerm)){
            wildcardQuery= makeWilcardQueryArray(searchTerm, terms);
            obj = { query : { 
              bool: { should : wildcardQuery} 
            }}
          }
          
          if(!Utilities.isNullOrEmpty(searchTerm) && (!Utilities.isNullOrEmpty(filters) || !Utilities.isNullOrEmpty(dateRange))){
            obj.query = {
              bool : {
                must : [
                  {bool: { should : wildcardQuery}}
                ] 
              }
            }
            if(!Utilities.isNullOrEmpty(filters)){
              obj.query.bool.must = obj.query.bool.must.concat(makeMatchArray(filters));
            }
            if(!Utilities.isNullOrEmpty(dateRange)){
              obj.query.bool.must = obj.query.bool.must.concat(makeDateRangeArray(dateRange.key || 'createdAt',dateRange.startDate, dateRange.endDate));
            }
          }
  
          if(Utilities.isNullOrEmpty(searchTerm) && (!Utilities.isNullOrEmpty(filters) || !Utilities.isNullOrEmpty(dateRange))){
            obj.query = {
              bool : {
                must : []
              }
            }
            if(!Utilities.isNullOrEmpty(filters)){
              obj.query.bool.must = obj.query.bool.must.concat(makeMatchArray(filters));
            }
            if(!Utilities.isNullOrEmpty(dateRange)){
              obj.query.bool.must = obj.query.bool.must.concat(makeDateRangeArray(dateRange.key || 'createdAt',dateRange.startDate, dateRange.endDate));
            }
          }
  
          if(!Utilities.isNullOrEmpty(sort)){
            obj.sort = sort;
          }
          
          return obj;
        };      

        return new Promise((resolve, reject) => {
            Utilities.makeWebRequest(`${ELASTIC_SEARCH_URL}/${indexName}/_search?from=${offset}&size=${size}&scroll=1m`,'GET', searchObj()).then((body)=>{
                const data = body.hits.hits.map((item)=>{
                      const obj = item._source;
                      obj.id = item._id;
                      return obj;
                });
                const result = {
                    count: data.length,
                    scrollId : body._scroll_id,
                    totalCount : body.hits.total.value,
                    data
                }
                resolve(result);
            }).catch((err)=>{
                reject(err);
            });
        });
    }

    createNewAction(actionData){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/actions`,'POST', actionData);
    }

    updateAction(actionId,fields){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/actions`,'PUT', {id:actionId,...fields});
    }

    getRecipeConfig(){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/config/recipeConfig`,'GET');
    }

    getBranches(){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/config/all/branches`,'GET');
    }

    searchMaterials(searchTerm, filters){
        let obj = {
            sort:[{"displayOrder" : "asc"}],
            searchTerm : searchTerm || ""
        };
        if(filters){
            obj.filters = filters;
        }
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/materials/search`,'POST', obj);
    }

    searchActions(searchTerm, filters){
        return Utilities.makeWebRequest(`https://gottea-search-5gtrn.ondigitalocean.app/actions/search`,'POST', {
            sort:[{"createdAt" : "desc"}],
            searchTerm : searchTerm || "",
            filters : Utilities.isNullOrEmpty(filters) ? undefined : filters
        });
    }

    getLimetrayRevenueData(startTime, endTime){
        return Utilities.makeWebRequest(`https://analytics-uib-service.dashboards.limetray.com/widget/data/orders`,'POST', {
            "metrics": [
                {
                    "id": "order_count",
                    "field": "*",
                    "aggregation": "COUNT",
                    "displayName": "Order Count",
                    "is_monetary": false
                },
                {
                    "id": "sum_amount_paid",
                    "field": "revenue",
                    "aggregation": "SUM",
                    "displayName": "Revenue",
                    "is_monetary": true
                }
            ],
            "dimensions": [
                {
                    "key": "source",
                    "field": "source",
                    "displayName": "Source"
                },
                {
                    "key": "city",
                    "field": "outlet_city",
                    "displayName": "City"
                }
            ],
            "filters": [
                {
                    "field": "source_placed_at",
                    "op": ">=",
                    "value": startTime
                },
                {
                    "field": "source_placed_at",
                    "op": "<=",
                    "value": endTime
                },
                {
                    "field": "outlet_id",
                    "op": "IN",
                    "value": [
                        "37472"
                    ]
                },
                {
                    "field": "state_current",
                    "op": "IN",
                    "value": [
                        "DELIVERED",
                        "SETTLED",
                        "PICKEDUP",
                        "CLOSED"
                    ]
                }
            ],
            "sorts": []
        });
    }

    getLimetrayCustomerData(startTime, endTime){
        return Utilities.makeWebRequest(`https://analytics-uib-service.dashboards.limetray.com/order`,'POST', 
        {
            "selectedDate": [
                startTime,
                endTime
            ],
            "isBusinessDay": 0,
            "outletIds": [
                "37472"
            ],
            "orderType": ["DINEIN","TAKEAWAY"],
            "orderSource": [],
            "isPreOrder": [],
            "status": [],
            "paymentMode": [],
            "deliverySource": [],
            "user": [],
            "server": [],
            "deliveryBoy": [],
            "pageSize": 0
        }
        );
    }

    getLimetrayPaymentsData(startTime, endTime){
        return Utilities.makeWebRequest(`https://analytics-uib-service.dashboards.limetray.com/widget/data/orders/payments`,'POST', {
            "metrics": [
                {
                    "id": "c",
                    "field": "*",
                    "aggregation": "COUNT",
                    "displayName": "Payment Count",
                    "is_monetary": false
                },
                {
                    "id": "a",
                    "field": "amount",
                    "aggregation": "SUM",
                    "displayName": "Revenue",
                    "is_monetary": true
                }
            ],
            "dimensions": [
                {
                    "key": "name",
                    "field": "name",
                    "function" :"UPPER(#field)",
                    "displayName": "Payment Mode"
                }
            ],
            "filters": [
                {
                    "field": "source_placed_at",
                    "op": ">=",
                    "value": startTime
                },
                {
                    "field": "source_placed_at",
                    "op": "<=",
                    "value": endTime
                },
                {
                    "field": "outlet_id",
                    "op": "IN",
                    "value": [
                        "37472"
                    ]
                },
                {
                    "field": "state_current",
                    "op": "IN",
                    "value": [
                        "DELIVERED",
                        "SETTLED",
                        "PICKEDUP",
                        "CLOSED"
                    ]
                }
            ],
            "sorts": []
        });
    }

    getLimetrayItemData(startTime, endTime){
        return Utilities.makeWebRequest(`https://analytics-uib-service.dashboards.limetray.com/widget/data/orders`,'POST', {
            "metrics": [
                {
                    "id": "item_qty",
                    "field": "items.quantity",
                    "filters": [
                        {
                            "field": "#0",
                            "values": [
                                "False"
                            ]
                        }
                    ],
                    "aggregation": "SUM",
                    "displayName": "Item Count",
                    "is_monetary": false,
                    "dependencies": [
                        "is_order_non_chargeable"
                    ]
                },
                {
                    "id": "items_subtotal",
                    "field": "items.total_item_price",
                    "aggregation": "SUM",
                    "displayName": "Subtotal",
                    "is_monetary": true
                }
            ],
            "dimensions": [
                {
                    "key": "name",
                    "field": "items.name",
                    "displayName": "Item Name"
                },
                {
                    "key": "name",
                    "field": "items.name",
                    "displayName": "Item Name"
                },
                {
                    "key": "type",
                    "field": "type",
                    "displayName": "Service"
                },
                {
                    "key": "source",
                    "field": "source",
                    "displayName": "Source"
                }
            ],
            "filters": [
                {
                    "field": "source_placed_at",
                    "op": ">=",
                    "value": startTime
                },
                {
                    "field": "source_placed_at",
                    "op": "<=",
                    "value": endTime
                },
                {
                    "field": "outlet_id",
                    "op": "IN",
                    "value": [
                        "37472"
                    ]
                },
                {
                    "field": "state_current",
                    "op": "IN",
                    "value": [
                        "DELIVERED",
                        "SETTLED",
                        "PICKEDUP",
                        "CLOSED"
                    ]
                },
                {
                    "field": "is_order_non_chargeable",
                    "op": "IN",
                    "value": [
                        "True",
                        "False"
                    ]
                }
            ],
            "sorts": []
        });
    }

    getShopifyOrderData(startTime, endTime){
        return Utilities.makeWebRequest(`https://us-central1-gottea-3daa4.cloudfunctions.net/sales/shopify`,'POST', {
        startTime,
        endTime
        });
    }

    getLimetrayAddOnData(startTime, endTime){
        return Utilities.makeWebRequest(`https://analytics-uib-service.dashboards.limetray.com/widget/data/orders`,'POST', {
            "metrics": [
                {
                    "id": "addon_qty",
                    "field": "items.addons.quantity",
                    "aggregation": "SUM",
                    "displayName": "Addon Count",
                    "is_monetary": false
                }
            ],
            "dimensions": [
                {
                    "key": "name",
                    "field": "items.addons.name",
                    "displayName": "Addon Name"
                },
                {
                    "key": "type",
                    "field": "type",
                    "displayName": "Service"
                },
                {
                    "key": "source",
                    "field": "source",
                    "displayName": "Source"
                }                
            ],
            "filters": [
                {
                    "field": "source_placed_at",
                    "op": ">=",
                    "value": startTime
                },
                {
                    "field": "source_placed_at",
                    "op": "<=",
                    "value": endTime
                },
                {
                    "field": "outlet_id",
                    "op": "IN",
                    "value": [
                        "37472"
                    ]
                },
                {
                    "field": "state_current",
                    "op": "IN",
                    "value": [
                        "DELIVERED",
                        "SETTLED",
                        "PICKEDUP",
                        "CLOSED"
                    ]
                },
                {
                    "field": "is_order_non_chargeable",
                    "op": "IN",
                    "value": [
                        "True",
                        "False"
                    ]
                }
            ],
            "sorts": []
        });
    }
    
    getHourwiseSalesSummary(startTime, endTime){
        return Utilities.makeWebRequest(`https://analytics-uib-service.dashboards.limetray.com/widget/data/orders`,'POST', 
        {
            "metrics": [
                {
                    "id": "c",
                    "field": "*",
                    "aggregation": "COUNT",
                    "displayName": "Order Count",
                    "is_monetary": false
                },
                {
                    "id": "sum_amount_paid",
                    "field": "revenue",
                    "aggregation": "SUM",
                    "displayName": "Revenue",
                    "is_monetary": true
                }
            ],
            "dimensions": [
                {
                    "key": "tz_hour",
                    "field": "source_placed_at",
                    "function": "to_char((to_timestamp(#field / 1000) AT time zone #0), 'HH24') || '-' || to_char((to_timestamp((#field + 3600000) / 1000) AT time zone #0), 'HH24')",
                    "displayName": "Hour",
                    "dependencies": [
                        "outlet_timezone"
                    ]
                }
            ],
            "filters": [
                {
                    "field": "source_placed_at",
                    "op": ">=",
                    "value": startTime
                },
                {
                    "field": "source_placed_at",
                    "op": "<=",
                    "value": endTime
                },
                {
                    "field": "outlet_id",
                    "op": "IN",
                    "value": [
                        "37472"
                    ]
                },
                {
                    "field": "state_current",
                    "op": "IN",
                    "value": [
                        "DELIVERED",
                        "SETTLED",
                        "PICKEDUP",
                        "CLOSED"
                    ]
                }
            ],
            "sorts": [
                {
                    "id": "tz_hour",
                    "direction": "ASC"
                }
            ]
        }
        );
    }

    getHourwiseItemsSummary(startTime, endTime){
        return Utilities.makeWebRequest(`https://analytics-uib-service.dashboards.limetray.com/widget/data/orders`,'POST', 
        {
            "metrics": [
                {
                    "id": "c",
                    "field": "items.quantity",
                    "aggregation": "SUM",
                    "displayName": "Item Count",
                    "is_monetary": false
                }
            ],
            "dimensions": [
                {
                    "key": "tz_hour",
                    "field": "source_placed_at",
                    "function": "to_char((to_timestamp(#field / 1000) AT time zone #0), 'HH24') || '-' || to_char((to_timestamp((#field + 3600000) / 1000) AT time zone #0), 'HH24')",
                    "displayName": "Hour",
                    "dependencies": [
                        "outlet_timezone"
                    ]
                }
            ],
            "filters": [
                {
                    "field": "source_placed_at",
                    "op": ">=",
                    "value": startTime
                },
                {
                    "field": "source_placed_at",
                    "op": "<=",
                    "value": endTime
                },
                {
                    "field": "outlet_id",
                    "op": "IN",
                    "value": [
                        "37472"
                    ]
                },
                {
                    "field": "state_current",
                    "op": "IN",
                    "value": [
                        "DELIVERED",
                        "SETTLED",
                        "PICKEDUP",
                        "CLOSED"
                    ]
                }
            ],
            "sorts": [
                {
                    "id": "tz_hour",
                    "direction": "ASC"
                }
            ]
        }
        );
    }


    saveEmail(email){
        return Utilities.makeWebRequest(`${API_URL}/users/gotathome/subscribe`,'POST', { email });
    }
}

/* -- Random Other Store -- 6098 2306 4935[big chill], 6101[ours]*/
//1902[merchantId big chill] 4935[big chill]