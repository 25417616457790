import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import Login from './components/Pages/Login/Login';
import Reports from './components/Pages/Reports/Reports';
import TheoreticalCogs from './components/Pages/TheoreticalCogs/TheoreticalCogs';
import ConsumptionReports from './components/Pages/ConsumptionReports/ConsumptionReports';
import ConsumptionPrediction from './components/Pages/ConsumptionPrediction/ConsumptionPrediction';
import ShopifyReports from './components/Pages/ShopifyReports/Reports';
import CompanyReports from './components/Pages/CompanyReports/Reports';
import HourwiseSales from './components/Pages/HourwiseSales/HourwiseSales';
import Customers from './components/Pages/Customers/Customers';
import Announcement from './components/Pages/Announcement/Announcement';
import InventoryOrder from './components/Pages/InventoryOrder/InventoryOrder';
import Wastage from './components/Pages/Wastage/Wastage';
import Actions from './components/Pages/Actions/Actions';
import QRGiftCard from './components/Pages/QRGiftCard/QRGiftCard';
import CroffleTracker from './components/Pages/CroffleTracker/CroffleTracker';
import DropCounter from './components/Pages/DropCounter/DropCounter';
import Tasks from './components/Pages/Tasks/Tasks';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import './css/common.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
      <Router>
        <div className="contentWrapper">
        <Switch>
            <Route exact path="/" component={Reports}/>
            <Route exact path="/consumption" component={ConsumptionReports}/>
            <Route exact path="/consumption-prediction" component={ConsumptionPrediction}/>
            <Route exact path="/cogs" component={TheoreticalCogs}/>
            <Route exact path="/shopify" component={ShopifyReports}/>
            <Route exact path="/tasks" component={Tasks}/>
            <Route exact path="/drop" component={DropCounter}/>
            <Route exact path="/overview" component={CompanyReports}/>
            <Route exact path="/croffles" component={CroffleTracker}/>
            <Route exact path="/customers" component={Customers}/>
            <Route exact path="/announcement" component={Announcement}/>
            <Route exact path="/inventoryorder" component={InventoryOrder}/>
            <Route exact path="/wastage" component={Wastage}/>
            <Route exact path="/actions" component={Actions}/>
            <Route exact path="/qrgiftcard" component={QRGiftCard}/>
            <Route exact path="/hourwisesales" component={HourwiseSales}/>
            <Route path="/login" component={Login}/>
        </Switch>
        </div>
      </Router>
      </Provider>
    </div>
  );
}

export default App;
