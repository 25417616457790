import React from 'react';
import './StatsTable.css';
import Utilities from '../../../js/Utilities';
import Loader from '../Loader/Loader';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faBars, faShippingFast, faStore } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";

Chart.defaults.set('plugins.legend', {
display:false
});

const flavorDic = {
    'Iced Americano':{key :'icedamericano', colour:'#485460'},
    'Hot Americano':{key :'americanohot', colour:'#485460'},
    'Green Matcha' : {key : 'matcha', colour : '#485731'},
    'Korean Banana Milk' :  {key: 'banana', colour: '#E1C758'},
    'Korean Banana Latte' :  {key: 'bananalatte', colour: '#E1C758'},
    'Berry Merry Matcha' : {key : 'berrymerry', colour : ''},
    'Strawberry Matcha' : {key : 'strawberrymatcha', colour : 'linear-gradient(#EAB5C2, #A7C991)', isGradient : true},
    'Earl Grey' : {key : 'earlgrey', colour : '#9AA594'},
    'Hot Earl Grey' : {key : 'earlgreyhot', colour : '#9AA594'},
    'Royal Milk Tea' : {key : 'royalmilktea', colour : '#3853A3'},
    'Hot Royal Milk Tea' : {key : 'royalmilkteahot', colour : '#3853A3'},
    'Ivory Silk' : {key : 'ivorySilk', colour : '#D2BBA2'},
    'Taro Purple' : {key : 'taropurple', colour : '#634C91'},
    'Blueberry Bliss' : {key : 'blueberrybliss', colour : '#004C91'},
    'Taro' : {key : 'taro', colour : '#634C91'},
    'Caramel Brown' : {key : 'caramel', colour : '#875020'},
    'Brown Sugar Boba' : {key : 'caramel', colour : '#875020'},
    'Classic Black' : {key : 'classic', colour : '#3C3333'},
    'Strawberry Blossom' : {key : 'blossom', colour : '#C44191'},
    'Strawberry Sparkle' : {key : 'sparkle', colour : '#D73663'},
    'Seoul Sesame' : { key : 'seoulsesame', colour : '#828282'},
    'Tropical Thai' : {key : 'thai', colour : '#CA8346'},
    'Scarlet Jamong' : {key : 'jamong', colour : '#FF4D4D'},
    'Grapefruit Iced Tea' : {key : 'grapefruit', colour : '#FF4D4D'},
    'Mango Coconut Milk' : {key : 'mangococonut', colour : '#FF8C00'},
    'Chocolate Milk' : { key: 'chocomilk', colour : '#4A2B25'},
    'Hot Chocolate Milk' : { key: 'chocomilkhot', colour : '#4A2B25'},
    'Mocha Latte' : { key: 'mochalatte', colour : '#583933'},
    'Hot Mocha Latte' : { key: 'mochalattehot', colour : '#583933'},
    'Hibiscus Iced Tea' : {key : 'hibiscus', colour : '#A92D33'},
    'Koko Caphe' : {key : 'kokocaphe', colour : '#756E30'},
    'Peachy Yuja' : {key : 'peachyyuja', colour : '#E08D77'},
    'Peachy Oolong' : {key : 'peachyoolong', colour : '#FABDA4'},
    'Hot Peachy Oolong' : {key : 'peachyoolonghot', colour : '#FABDA4'},
    'Blueberry Croffle' : {key : 'blueberrycroffle', colour : '#634C91'},
    'Strawberry Croffle' : {key : 'strawberrycroffle', colour : '#C44191'},
    'Corn Cheese Croffle' : {key : 'corncheeseecroffle', colour : '#ffbe76'},
    'Custard Croffle' : {key : 'custardcroffle', colour : '#feca57'},
    'Dill & Cheese Croffle' : {key : 'dillcheesecroffle', colour : '#EAB543'},
    'Dill And Cheese Croffle' : {key : 'dillcheesecroffle', colour : '#EAB543'},
    'Original Croffle' : {key : 'dillcheesecroffle', colour : '#875020'},
    'Non - Veg Sandwich' : {key : 'nonvegsandwich', colour : '#eb4d4b'},
    'Non-Veg Sandwich' : {key : 'nonvegsandwich', colour : '#eb4d4b'},
    'Veg Sandwich' : {key : 'nonvegsandwich', colour : '#10ac84'},
    'Got Spicy Fried Chicken' : {key : 'chicken', colour : '#CA8346'},
    'Got Fried Chicken' : {key : 'chicken', colour : '#CA8346'},
    'Caramel Brown Scone' : {key : 'caramelbrownscone', colour : '#875020'},
}

class StatsTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
           showPieChart : false,
           searchVal : '',
           sortMode: 'count',
           stats:[],
           filteredStats:[],
           timeout:null
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.data !== this.props.data){
            const {data} = this.props;
            const {sortMode} = this.state;
            let tempStats = [];
            let toppingsTotal  = {};
            for(let key in data){
                let toppingsBreakdown = [];
                for(let t in data[key].toppings){
                    if(t.toLowerCase().trim().includes("tapioca") || t.toLowerCase().trim().includes("no topping")){
                        continue;
                    }
                    if(toppingsTotal[t]){
                        toppingsTotal[t] += data[key].toppings[t];
                    } else {
                        toppingsTotal[t] = data[key].toppings[t];
                    }
                    let tPercent = parseFloat(data[key].toppings[t]) / parseFloat(data[key].count);
                    toppingsBreakdown.push({
                        name : t, val:parseFloat(data[key].toppings[t]), percent: `${(tPercent * 100.0).toFixed(2)}`
                    });
                }
    
                tempStats.push({ 
                    name : key, 
                    totalValue: data[key].value || 0.0,
                    count : data[key].count,
                    toppings: toppingsBreakdown || [],
                    regularCount : data[key].regularCount,
                    largeCount : data[key].largeCount,
                    delivery:data[key].delivery, 
                    takeaway:data[key].takeaway, 
                    dineIn: data[key].dineIn, 
                    isGradient:flavorDic[key] ?  flavorDic[key].isGradient :  false, 
                    colour : flavorDic[key] ?  flavorDic[key].colour :  '#C1A97E', 
                    flavorName : flavorDic[key] ?  flavorDic[key].key :  'generalItem'
                });
            }
            // tempStats.sort(function(a, b){return (b.takeaway+b.dineIn)-(a.takeaway+a.dineIn)});
            tempStats.sort(function(a, b){return (b.totalValue)-(a.totalValue)});
            this.setState({
                stats:tempStats,
                filteredStats:tempStats,
                searchVal:''
            });
        }
    }    

    handlePieChartClick = () => {
        this.setState({
            showPieChart : !this.state.showPieChart
        });
    }

    handleSortClick = (mode) => {
        const {filteredStats} = this.state;
        let newSortMode = this.state.sortMode === 'count' ? mode : 'count';
        let tempStats = [...filteredStats];
        if(newSortMode === 'in-store'){
            tempStats.sort(function(a, b){return (b.takeaway+b.dineIn)-(a.takeaway+a.dineIn)});
        } else {
            tempStats.sort(function(a, b){return b[`${newSortMode}`]-a[`${newSortMode}`]});
        }
        this.setState({
            sortMode : newSortMode,
            filteredStats: tempStats
        });
    }

    handleSearchChange = (e) => {
        const {stats, timeout} = this.state;
        const searchVal = e.target.value;

        // Clear the previous timeout if the user keeps typing
        if (timeout) {
            clearTimeout(this.state.timeout);
        }
        // Set a new timeout for the debounce delay (e.g., 300ms)
        const newTimeout = setTimeout(() => {
            let tempStats = [...stats];
            if(searchVal){
                tempStats = tempStats.filter((item)=>{
                    if(item.name.toLowerCase().includes(searchVal.toLowerCase())){
                        return true;
                    } else {
                        return false;
                    }
                });
            }
            this.setState({
                searchVal,
                filteredStats: tempStats
            });
        }, 300); // 300ms debounce

        // Update the state with the new timeout and search value
        this.setState({
            searchVal,
            timeout: newTimeout
        });
    }

render(){
    const { data, title, loading,skipFlavourBreakdown, showLargeAndSmall } = this.props;
    const { filteredStats, stats, showPieChart, sortMode, searchVal } = this.state;
    let maxCount = 0;
    let largeCount = 0;
    let regularCount = 0;
    let total = 0;
    let deliveryCount = 0;
    let takeawayCount = 0;
    let dineInCount = 0;
    let toppingsTotal  = {};
    for(let key in data){
        if(data[key].count >= maxCount){
            maxCount = data[key].count
        }
        largeCount += data[key].largeCount;
        regularCount += data[key].regularCount;
        total += data[key].count;
        deliveryCount += data[key].delivery;
        takeawayCount += data[key].takeaway;
        dineInCount  += data[key].dineIn;
    }

    let pieData = {
        labels : filteredStats.map((item,index)=> item.name),
        datasets : [
            {
                label : title,
                backgroundColor : filteredStats.map((item,index)=> item.colour),
                data : filteredStats.map((item,index)=> item.count)
            }
        ]
    };

    let upperLimit = maxCount < 10 ? (parseInt(maxCount * 1.20) + 3) : parseInt(maxCount * 1.20);

    if(stats.length <= 0){
        return null;
    }

    const headers = [
        { label: "Item Name", key: "name" },
        { label: "Regular Count", key: "regularCount" },
        { label: "Large Count", key: "largeCount" }
      ];
    
    
    return <div className='statsTable'>
        {loading ? <Loader/> :
            <React.Fragment>
            <div className="titleContainer">
            <h3>{title}</h3>
            <div className='searchBar'>
            <input value={searchVal} onChange={this.handleSearchChange}/>
            </div>
            </div>
                        {
                            filteredStats.length ? <CSVLink 
                            className={"csvButton"}
                            data={filteredStats}
                            headers={headers}
                            filename={`${title}.csv`}
                          >
                            Download CSV
                          </CSVLink> : ''
                        }
            <div className='buttonContainer'>
            {filteredStats.length > 0  ? <div className='iconBox' onClick={this.handlePieChartClick}><FontAwesomeIcon size={'lg'} className='pieIcon' icon={showPieChart ? faBars : faChartBar} />Show Chart</div> : ''}
            {filteredStats.length > 0  ? <div className='iconBox' onClick={()=>{this.handleSortClick('delivery')}}><FontAwesomeIcon size={'lg'} className='pieIcon' icon={faShippingFast}/>Delivery Sort</div> : ''}
            {filteredStats.length > 0  ? <div className='iconBox' onClick={()=>{this.handleSortClick('in-store')}}><FontAwesomeIcon size={'lg'} className='pieIcon' icon={faStore}/>In-Store Sort</div> : ''}
            </div>
            
            {filteredStats.length > 0 ? <p className="totalAmount">Total : {Utilities.getTotal(filteredStats)}</p> : ''}
            {showLargeAndSmall ? <p className="sizeDivision"><span>Regular</span> : {regularCount} ({Utilities.formatAsPercent(regularCount/Utilities.getTotal(filteredStats))}) | <span>Large</span> : {largeCount} ({Utilities.formatAsPercent(largeCount/Utilities.getTotal(filteredStats))})</p> :  ''}
            <p className="sizeDivision"><span>Dine In</span> : {dineInCount} ({Utilities.formatAsPercent(dineInCount/total)}) | <span>Takeaway</span> : {takeawayCount} ({Utilities.formatAsPercent(takeawayCount/total)}) | <span>Delivery</span> : {deliveryCount} ({Utilities.formatAsPercent(deliveryCount/total)})</p>
            <p className="sizeDivision">{Object.keys(toppingsTotal).map((k)=><><span>{k}</span>{` : ${toppingsTotal[k]} (${((parseFloat(toppingsTotal[k]) / parseFloat(Utilities.getTotal(filteredStats)))*100.0).toFixed(2)}%) `}</>)}</p>
            { showPieChart ? 
            <div className="pieChartContainer"><div>
                <Bar 
                plugins={[ChartDataLabels]}
                data={pieData} 
                options={
                    {
                        plugins : {
                            datalabels : {
                                color: '#47908A',
                                display:'auto',
                                anchor:'end',
                                align:'end',
                                rotation : 0,
                                clip:true,
                                clamp:true,
                                offset:0,
                                textAlign:'center',
                                font:{
                                    family:'Lexend Deca',
                                    size: 14,
                                    weight : 'bold'
                                },
                                formatter : function(value, context){
                                    return `${value}\n(${((value/context.dataset.data.reduce((a,b)=>a+b))*100).toFixed(2)}%)`;
                                }
                              }
                        },
                        scales : {
                            y : {
                                max : upperLimit
                            }
                        }
                    }
                }/>
                </div></div> : 
            (
                filteredStats.length > 0 ? 
                filteredStats.map((item, index)=>{
                    return item.name ? <div key={`flavorRow${index}${item.name}`} style={item.isGradient ? {backgroundImage: item.colour } : {backgroundColor : item.colour }} className={`statsRow`}>
                        <div className='flavourContainer'><div className='countCircle'>{item.count}</div>
                        {showLargeAndSmall ? <>
                        <div className='sizeCircle'><span>R</span>{item.regularCount}</div>
                        <div className='sizeCircle'><span>L</span>{item.largeCount}</div>
                        </> : ''}
                <div className="flavourBreakDown"><span>{item.name}</span>{skipFlavourBreakdown ? '' : `In-Store : ${item.dineIn+item.takeaway} / Dine In : ${item.dineIn} / Takeaway: ${item.takeaway} / Delivery : ${item.delivery}`}</div>
                </div>
                <div className='toppingContainer'>{item.toppings.map((t)=><div className='toppingRatio'><span className='toppingName'>{t.name}</span><img className="toppingPic" src={`https://firebasestorage.googleapis.com/v0/b/gottea-3daa4.appspot.com/o/product-images%2F${encodeURIComponent(t.name)}.png?alt=media&token=53da054f-02d8-41e2-aefc-d7dd259aabd2`} alt={t.name} /> X{t.val} ({t.percent}%)</div>)}</div>
                <div className='itemValue'>₹{item.totalValue.toFixed(2)}</div>
                </div> : '';}) : <p>There is No Data</p>)
            } 
        </React.Fragment> }
        </div>
}

}

export default StatsTable;