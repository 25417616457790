import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import ConsumptionTable from '../../Common/ConsumptionTable/ConsumptionTable';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './Reports.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Line } from 'rc-progress';
import { Bar } from 'react-chartjs-2';

const Client = new Api();
const moment = require('moment-timezone');
const currentBranches = [
    { name: "Safdarjung", value:"Safdarjung"},
    { name: "Galleria", value:"Galleria"},
    { name: "GK1", value:"Got Tea - GK1"},
    { name: "GK2", value:"GreaterKailash2"},
    { name: "GCR", value:"Got Tea - Golf Course Road"},
    { name: "Punjabi Bagh", value:"Got Tea - Punjabi Bagh"},
    { name: "Nirvana", value:"Got Tea - Nirvana"},
    { name: "Vasant Kunj", value:"Got Tea - Vasant Kunj"},
    { name: "Panchsheel Enclave", value:"Got Tea - Panchsheel Enclave"}
    // { name: "Events", value:"Events"}
];
class TheoreticalCogs extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            viewMode:'day',
            storeMode:'all',
            browser : Utilities.getCurrentBrowser(),
            currentMonth:moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM'),
            startDate : moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM-DD'),
            endDate : moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM-DD'),
            cash : 0.0,
            cashCount : 0,
            sourceTotals:{},
            recipeConfig : [],
            orderTypeTotals: {},
            branchTotals:[],
            money : 0.0,
            data : null,
            stats : null,
            wastageAmount:0.0,
            consumptionAmount:0.0,
            orderCount : 0,
            snackCount : 0,
            drinksCount : 0,
            totalDrinksValue:0.0,
            totalFoodValue:0.0,
            orders:null,
            addOnItems : [],
            orderItems : [],
            unknownOrderCount : 0,
            phoneNum:props.match.params.phonenum,
            daysYet:1,
            totalAmount:0.0,
            totalNetAmount:0.0,
            averageDailyRevenue:0.0,
            expectedTotal:0.0,
            totalDiscounts:0.0,
            selectedBranches:["Safdarjung","GreaterKailash2","Galleria","Got Tea - Golf Course Road","Got Tea - Punjabi Bagh","Got Tea - Nirvana","Got Tea - Vasant Kunj","Got Tea - GK1","Got Tea - Panchsheel Enclave"]
        }
        this.timer = null;
    }

    loadOrders = async ()=>{
        const {startDate, endDate, viewMode, storeMode, selectedBranches, currentMonth } = this.state;
        let startDateString = moment.tz(startDate,'Asia/Kolkata').format('YYYY-MM-DD');
        let endDateString = moment.tz(endDate,'Asia/Kolkata').format('YYYY-MM-DD');

        if(viewMode === "month"){
            startDateString = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
            endDateString   = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
        }
        
        let daysYet = moment(startDate).endOf('month').diff(moment(endDate).startOf('month'), 'days');
        if(moment(startDate).endOf('month') > moment().endOf('day')){
            daysYet = moment().endOf('day').diff(moment(endDate).startOf('month'), 'days');
        }
        daysYet = Math.abs(daysYet);
        this.setState({
            daysYet,
            loading:true
        },()=>{
            Client.getGotTeaOrderData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf(),selectedBranches).then((orderData)=>{
                let expectedTotal = 0.0;
                let averageDailyRevenue = 0.0;
                let branchTotals = [];
                if(viewMode === "month"){
                    let numDaysInMonth = moment(currentMonth).daysInMonth();
                    let netRevSoFar = orderData.totalNetAmount - orderData.totalNetAmountForLastDay;
                    averageDailyRevenue = netRevSoFar / daysYet;
                    expectedTotal = (averageDailyRevenue * numDaysInMonth);
                }
                branchTotals = Object.keys(orderData.branchTotals).map((key)=>({branch : key, value : orderData.branchTotals[key]}));
                branchTotals.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
                Client.getRecipeConfig().then((recipeData)=>{
                    this.setState({
                        recipeConfig:recipeData.ingredients,
                        totalOrderCount:orderData.orderCount,
                        wastageAmount:orderData.wastageMonetaryValue,
                        consumptionAmount:orderData.cogsMonetaryValue,
                        totalAmount : orderData.totalAmount,
                        totalNetAmount : orderData.totalNetAmount,
                        totalDiscounts : orderData.totalDiscounts,
                        sourceTotals: orderData.sourceTotals,
                        periodTotals:orderData.periodTotals,
                        revenueTotals : Object.keys(orderData.sourceTotals).map((key)=>({Source : key,...orderData.sourceTotals[key]})),
                        orderTypeTotals : orderData.orderTypeTotals,
                        branchTotals:branchTotals,
                        orderItems:orderData.items,
                        expectedTotal:expectedTotal,
                        averageDailyRevenue:averageDailyRevenue,
                        loading:false
                    },()=>{
                        this.filterOrders();
                    });
                });
            }).catch((err)=>{
                console.log('GET GOT TEA DATA ERROR =>', err);
            });
        })
    }

    filterOrders = () =>{
        const { orderItems} = this.state;
        let overallItemCounter = {};

        let arr = [];
        let money = 0.0;
        let drinksCount = 0;
        let snackCount = 0;
        let totalDrinksValue = 0.0;
        let totalFoodValue = 0.0;
        for(const item of orderItems){
            let orderType = item.Service.toLowerCase();
            let source = item.Source.toLowerCase();
            if(source.includes("zomato")){
                source = "zomato";
            } else if(source.includes("swiggy")){
                source = "swiggy";
            } else if(source.includes("takeaway")){
                source = "takeaway";
            } 
            let isDelivery = orderType === 'delivery';
            let isDineIn = orderType === 'dinein';
            let isTakeaway = orderType === 'takeaway';
            money += parseFloat(item["Revenue"]);
            let itemName = item["Item Name"].trim();
            if(itemName.trim() === "Peachy  Oolong"){
                itemName = "Peachy Oolong";
            }
            if(itemName.toLowerCase().includes("croffle")){
                if(itemName.trim() === "Bubble Tea + Croffle Combo" || itemName.trim() === "1 Bubble Tea + Original Croffle"){
                    itemName = "1 Bubble Tea + 1 Croffle";
                }
                itemName = itemName.replace("(-10%)","").replace("(-10%)","").replace("Croffle - Original","Original Croffle").trim();
                if(itemName.trim() === "Corn and Cheese Croffle"){
                    itemName = "Corn Cheese Croffle";
                }
                if(itemName.trim() === "Dill And Cheese Croffle"){
                    itemName = "Dill & Cheese Croffle";
                }
                if(itemName.trim() === "Dill &amp; Cheese Croffle"){
                    itemName = "Dill & Cheese Croffle";
                }
            } else if(itemName.toLowerCase().includes("sandwich")){
                if(itemName.trim() === "Sandwich Veg"){
                    itemName = "Veg Sandwich";
                }
                if(itemName.trim() === "Non-Veg Sandwich" || itemName.trim() === "Sandwich Non Veg" || itemName.trim() === "Sandwich Chicken"){
                    itemName = "Non - Veg Sandwich";
                }
            } else {
                itemName = itemName.replace("Bubble Tea", "").replace(" (10% Off)","").replace("(-10%)","").replace("[No Bubbles]","").replace("No Bubbles","").replace("[New]","").trim();
            }
            if(itemName.toLowerCase().trim() === "banana milk"){
                itemName = "Korean Banana Milk";
            }
            

            if(Utilities.isNullOrEmpty(overallItemCounter[itemName])){
                overallItemCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0, largeCount:0, regularCount:0, value:0.0};
            }
                overallItemCounter[itemName].largeCount += parseInt(item["Large Count"]);
                overallItemCounter[itemName].regularCount += parseInt(item["Regular Count"]);
                overallItemCounter[itemName].count = overallItemCounter[itemName].count + parseInt(item["Item Count"]);
                overallItemCounter[itemName].value = overallItemCounter[itemName].value + parseFloat(item["Subtotal"]);
                if(overallItemCounter[itemName].toppings){
                    for(let topping in item["Toppings"]){
                        if(overallItemCounter[itemName].toppings[topping]){
                            overallItemCounter[itemName].toppings[topping] += item["Toppings"][topping];
                        } else {
                            overallItemCounter[itemName].toppings[topping] = item["Toppings"][topping];
                        }
                    }
                    for(let topping in item["Extra Toppings"]){
                        if(overallItemCounter[itemName].toppings[topping]){
                            overallItemCounter[itemName].toppings[topping] += item["Extra Toppings"][topping];
                        } else {
                            overallItemCounter[itemName].toppings[topping] = item["Extra Toppings"][topping];
                        }
                    }
                } else {
                    overallItemCounter[itemName].toppings = {};
                    for(let topping in item["Toppings"]){
                        if(overallItemCounter[itemName].toppings[topping]){
                            overallItemCounter[itemName].toppings[topping] += item["Toppings"][topping];
                        } else {
                            overallItemCounter[itemName].toppings[topping] = item["Toppings"][topping];
                        }
                    }
                    for(let topping in item["Extra Toppings"]){
                        if(overallItemCounter[itemName].toppings[topping]){
                            overallItemCounter[itemName].toppings[topping] += item["Extra Toppings"][topping];
                        } else {
                            overallItemCounter[itemName].toppings[topping] = item["Extra Toppings"][topping];
                        }
                    }
                }
        }

        this.setState({ 
            data : arr, 
            overallItemCounter,
            orderCount : arr.length, 
            snackCount, 
            drinksCount, 
            totalDrinksValue,
            totalFoodValue,
            money});
    }
    
    getOrders = (newStatus)=>{
        this.loadOrders();
    }

    handleBranchesChange = (event) => {
        this.setState({
          selectedBranches: event.target.value,
        });
      };

    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadOrders();
            } else {
                window.location.href = '/login';
            }
        });
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    setStartDate = (e) => {
        this.setState({startDate : e.target.value},()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    setMonth = async (e) => {
        const startDate = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
        const endDate   = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
        this.setState({
            currentMonth : e.target.value,
            startDate, 
            endDate
        },()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    onUpdateClick = ()=>{
        this.loadOrders();
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value},()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    handleChangeMode = (event, mode)=>{
            let updateObj = {
                viewMode : mode
            };
            this.setState(updateObj,()=>{
                this.loadOrders();
            });
    }

    handleChangeStoreMode = (event)=>{
        let updateObj = {
            storeMode : event.target.value
        };
        this.setState(updateObj,()=>{
            this.loadOrders();
        });
    }
    
    
    render(){
        const { 
            viewMode,
            recipeConfig,
            selectedBranches,
            overallItemCounter,
            currentMonth,
            startDate, 
            endDate,
            loading,
         } = this.state;
    
        return <div className='reportContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
                <div className='infoDiv'>
                <ToggleButtonGroup
                    style={{marginTop:20, marginBottom:10}}
                    color="primary"
                    disabled={loading}
                    value={viewMode}
                    exclusive
                    onChange={this.handleChangeMode}
                    >
                    <ToggleButton disabled={loading} value="day">Day</ToggleButton>
                    <ToggleButton disabled={loading} value="month">Month</ToggleButton>
                    </ToggleButtonGroup>

            <FormControl sx={{ minWidth:'320px', my: '1em'}}> 
                <InputLabel>Branches</InputLabel>
                <Select
                color="primary"
                multiple
                value={selectedBranches}
                label="Branches"
                onChange={this.handleBranchesChange}
                >
                {currentBranches.map((b, branchIndex)=><MenuItem key={b.name+branchIndex} disabled={loading} value={b.value}>{b.name}</MenuItem>)}
                </Select>
            </FormControl>
                
                { loading ? <Loader/> : <React.Fragment>
                    <div className='dateContainer'>
                    {viewMode === "day" ? <>                    
                    <input disabled={loading} type='date' className='dateInput' onChange={this.setStartDate} value={startDate}/>
                    <input disabled={loading} type='date' className='dateInput' onChange={this.setEndDate} value={endDate}/>
                    </>  : ''}
                    {viewMode === "month" ? <>
                    <input disabled={loading} type='month' className='dateInput' onChange={this.setMonth} value={currentMonth}/>
                    </> : ''}
                    <div className='updateBtn' onClick={this.onUpdateClick}> 
                    <FontAwesomeIcon className='btnIcon' icon={faArrowCircleRight} size='1x'/>
                    </div>
                    </div>
                    </React.Fragment>}
                    <ConsumptionTable loading={loading} recipeConfig={recipeConfig} title={`Theoretical Consumption`} data={overallItemCounter}/>
            </div> 
            </div>
            </div>
    } 
}

export default TheoreticalCogs;