import React from 'react';
import './ConsumptionTable.css';
import Utilities from '../../../js/Utilities';
import Loader from '../Loader/Loader';
import { CSVLink } from "react-csv";

class ConsumptionTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }

render(){
    const { data, branch, title, loading,recipeConfig } = this.props;

    let stats = [];
    let maxCount = 0;
    for(let key in data){
        if(data[key].count >= maxCount){
            maxCount = data[key].count
        }
        
        stats.push({ 
             name : key, 
             count : data[key].count,
             regularCount : data[key].regularCount,
             largeCount : data[key].largeCount,
             delivery:data[key].delivery, 
             takeaway:data[key].takeaway, 
             dineIn: data[key].dineIn, 
            });
    }
    stats.sort(function(a, b){return b.count-a.count});

 

    if(recipeConfig.length <= 0 || Utilities.isNullOrEmpty(data)){
        return null;
    }

    let ingredients = [];
    let ingTracker = {};
    let i = 0;
    for(let ing of recipeConfig){
        let temp = {name : ing.ingredientName, quantity : 0.0, batchQty:0.0, batchSize : ing.batchSize, unit : ing.unit, sku: ing.sku, cost: 0.0};
        let item = data[ing.itemName];   
        if(item){
            temp.quantity += ((item.largeCount || 0) * (ing.large || 0)) + ((item.regularCount || 0) * (ing.regular || 0));
            temp.batchQty += temp.quantity / ing.batchSize;
            temp.cost = ing.cost * temp.batchQty;
        }

        if(temp.sku === "Oleche"){
            console.log(`${ing.itemName} : ${temp.quantity} : ${temp.batchQty}`);
        }
        if(Utilities.isNullOrEmpty(ingTracker[temp.sku])){
            ingTracker[temp.sku] = {qty:temp.quantity,bQty:temp.batchQty, i:i, cost: ing.cost * temp.batchQty};
            ingredients.push(temp);
            i++;
        } else {
            ingTracker[temp.sku].qty += temp.quantity;
            ingTracker[temp.sku].bQty += temp.batchQty;
            ingredients[ingTracker[temp.sku].i].quantity = ingTracker[temp.sku].qty;
            ingredients[ingTracker[temp.sku].i].batchQty = ingTracker[temp.sku].bQty;
            ingredients[ingTracker[temp.sku].i].cost = ing.cost * ingTracker[temp.sku].bQty;
        }


        // let tapiocaQty = item.toppings["Tapioca Pearls"] || 0;

        // //TOPPINGS LOGIC
        // if(Utilities.isNullOrEmpty(ingTracker["Tapioca Pearls (raw)"])){
        //     ingTracker["Tapioca Pearls (raw)"] = {qty:,bQty:temp.batchQty, i:i};
        //     ingredients.push(temp);
        //     i++;
        // } else {
        //     ingTracker["Tapioca Pearls (raw)"].qty += temp.quantity;
        //     ingTracker["Tapioca Pearls (raw)"].bQty += temp.batchQty;
        //     ingredients[ingTracker["Tapioca Pearls (raw)"].i].quantity = ingTracker["Tapioca Pearls (raw)"].qty;
        //     ingredients[ingTracker["Tapioca Pearls (raw)"].i].batchQty = ingTracker["Tapioca Pearls (raw)"].bQty;
        // }
    }
    // let temp = {name : "Tapioca Pearls (raw)", quantity : 0.0, batchQty:0.0, batchSize : "3000", unit : "g", sku: "TAP_SKU"};
    ingredients.sort((a,b)=>{return b.batchQty - a.batchQty});
    
    const headers = [
        { label: "SKU", key: "sku" },
        { label: "Ingredient Name", key: "name" },
        { label: "Batch Size", key: "batchSize" },
        { label: "Unit", key: "unit" },
        { label: "Batch Quantity", key: "batchQty" },
        { label: "Quantity", key: "quantity" },
        { label: "Cost", key: "cost" }
      ];
    
    return <div className='consumptionTable'>
        {loading ? <Loader/> :
            <React.Fragment>
            <div className="titleContainer">
            <h3 style={{'text-align':'center'}}>{title}</h3>    
            </div>
            {
                ingredients.length ? <CSVLink 
                className={"csvButton"}
                data={ingredients}
                headers={headers}
                filename={`Theoretical-Consumption.csv`}
              >
                Download CSV
              </CSVLink> : ''
            }
            { ingredients.length > 0 ? 
                ingredients.map((item, index)=>{
                    return item.name ? <div key={`flavorRow${index}`} style={{backgroundColor : "#485460" }} className={`statsRow`}>
                         <div className='flavourContainer'><div className='countTag'>{item.quantity.toFixed(2)}{item.unit}</div>
                         <div className='countTag'>{(item.batchQty).toFixed(2)} Batches of {item.batchSize}{item.unit}</div>
                         <div className='countTag'>Cost : {(item.cost).toFixed(2)}</div>
                        <div className="flavourBreakDown"><span>{item.name}</span></div>
                </div></div> : '';}) : <p>There is No Data</p>
            } 
        </React.Fragment> }
        </div>
}

}

export default ConsumptionTable;